import React from "react";

import styled from "@emotion/styled";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

import { Container, Wrapper } from "../components/layout";
import { StyledButtonPill } from "../components/layout/button";
import { Layout } from "../components/layout/layout";
import { FromBottom, FromLeft, FromRight } from "../components/transitions";

const Separator = styled.hr`
  display: flex;
  margin: 0;
  border: 0;
  &:before {
    content: "";
    display: block;
    width: 6rem;
    border-top: 1rem solid var(--color-primary);

    @media (max-width: 500px) {
      border-top: 1rem solid var(--color-primary);
    }
  }
`;

const Heading = styled.h3`
  margin-bottom: 1rem;
`;

const Button = () => (
  <StyledButtonPill
    sx={{ width: "fit-content", marginTop: "2rem" }}
    onClick={() => navigate("/contact")}
  >
    Devenez membre
  </StyledButtonPill>
);

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "a-propos.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout background={data.hero} title="À Propos de nous">
      <Helmet>
        <title>Centre Léveil - À propos de nous</title>
      </Helmet>
      <Wrapper>
        <FromBottom>
          <Container>
            <h2>
              Nous offrons plusieurs types de services adaptés à vos besoins!
            </h2>
          </Container>
        </FromBottom>
      </Wrapper>
      <Wrapper id="crossfit" style={{ textAlign: "left" }}>
        <FromRight>
          <Container
            direction="column"
            style={{ alignItems: "flex-start", textAlign: "left" }}
          >
            <Heading>CrossFit</Heading>
            <Separator />
            <p>
              Le crossfit c&apos;est quoi ? C&apos;est des entraînements courts
              à haute intensité offrant une variété de mouvements fonctionnels.
              C&apos;est un mixte entre l&apos;haltérophilie, la gymnastique et
              l&apos;entraînement cardio-vasculaire.
            </p>
            <p>
              Le crossfit a fait ses preuves ! C&apos;est une approche efficace
              pour améliorer sa condition physique et atteindre des objectifs de
              mise en forme ou de performance.
            </p>
            <p>
              Que ce soit sous forme d&apos;AMRAP, d&apos;EMOM ou encore de
              renforcements musculaires, les cours sont toujours variés et
              adaptés à tous.
            </p>
            <Button />
          </Container>
        </FromRight>
      </Wrapper>
      <Wrapper id="spinning">
        <FromLeft>
          <Container
            direction="column"
            style={{ alignItems: "flex-end", textAlign: "right" }}
          >
            <Heading>Spinning</Heading>
            <Separator />
            <p>
              Axé sur l’endurance, la haute intensité et le cardio, le spinning
              vous fera vivre un entraînement hors du commun. Dans notre salle
              grandiose avec des néons fluorescents et une musique électrisante,
              vous aurez du fun à brûler des calories.
            </p>
            <p>
              Lors de ces cours de vélo, vous travaillerez en intervalle tout en
              tonifiant vos muscles du bas du corps. Nos coachs qualifiés et
              motivants sauront vous aider à vous surpasser de cours en cours !
              Allez, sautez dans vos souliers et venez l’essayer !
            </p>
            <Button />
          </Container>
        </FromLeft>
      </Wrapper>
      <Wrapper id="masso">
        <FromRight>
          <Container
            direction="column"
            style={{ alignItems: "flex-start", textAlign: "left" }}
          >
            <Heading>Massothérapie</Heading>
            <Separator />
            <p>
              Plus besoin de courir les quatre coins de la ville pour chercher
              un massothérapeute. Au Centre Léveil, nous aurons deux
              professionnels vous offrant des services de qualité.
            </p>
            <p>
              Prenez rendez-vous avec eux pour guérir vos bobos ou pour vous
              détendre après une grosse séance d’entraînement.
            </p>
            <Button />
          </Container>
        </FromRight>
      </Wrapper>
      <Wrapper id="kin3">
        <FromLeft>
          <Container
            direction="column"
            style={{ alignItems: "flex-end", textAlign: "right" }}
          >
            <Heading>Kinésiologie</Heading>
            <Separator />
            <p>
              Que ce soit pour des entraînements privés, pour améliorer vos
              bases en entraînement, pour une réhabilitation, pour une
              préparation à une compétition ou pour un suivi alimentaire, notre
              kinésiologue prendra le temps pour vous.
            </p>
            <p>
              Chaque membre aura un suivi complet avec ce professionnel
              passionné !
            </p>
            <Button />
          </Container>
        </FromLeft>
      </Wrapper>
    </Layout>
  );
};

export default About;
